import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBBtn, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaWhatsapp } from "react-icons/fa";
import LoadingScreen from '../components/loading';
import { HeaderBGRegister } from '../components/svgs';
import { LogoH } from '../components/svgs';

function End() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            const handleLoad = () => {
                setLoading(false);
            };

            window.addEventListener('load', handleLoad);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('load', handleLoad);
        }
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center justify-content-between" style={{}}>
                <MDBRow className='mb-4 qa-page-title-wrapper'>
                    <div className='qa-header-bg'>
                        <HeaderBGRegister />
                    </div>
                    <div className='qa-register-logo'>
                        <LogoH />
                    </div>
                </MDBRow>
                <MDBContainer className='mb-4 qa-container'>
                    <MDBRow>
                        <MDBCol md={12}>
                            <h1 className='qa-page-title'>Muito obrigado por responder o questionário!</h1>
                            <p className='qa-page-subtitle'>O resultado vai chegar em seu e-mail em breve, porém vale lembrar que é muito importante assistir o vídeo abaixo:</p>
                            <p className='qa-page-subtitle'>Junte-se ao nosso grupo para ficar por dentro das melhores dicas!</p>
                        </MDBCol>
                        <MDBCol md={12} className='d-flex justify-content-center'>
                            <a className='qa-button-r'>Entrar na Comunidade</a>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>
        </>
    );
}

export default End