import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBBtn, MDBTypography, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import LoadingScreen from '../components/loading';
import { SheilaLogo } from '../components/svgs';
import { HeaderBG } from '../components/svgs';
import { HeaderBGRegister } from '../components/svgs';
import { LogoH } from '../components/svgs';

const questions = [
    {
        question: '1- Quando o paciente compartilha um drama, você:',
        options: [
            { letter: 'A', text: 'Sente que vai carregar seus problemas nas costas pra sempre.' },
            { letter: 'B', text: 'Pensa "e agora, o que eu faço?"' },
            { letter: 'C', text: 'Fica com peso na consciência se cobrar.' },
            { letter: 'D', text: 'Quer ajudar tanto que se perde no meio do caminho.' },
            { letter: 'E', text: 'Estica o ouvido com carinho e fé, sem passar do limite.' }
        ],
        color: '#163970'
    },
    {
        question: '2- Depois da sessão, é provável que você:',
        options: [
            { letter: 'A', text: 'Fica remoendo o bolo até em casa.' },
            { letter: 'B', text: 'Se pergunta se realmente ajudou o paciente.' },
            { letter: 'C', text: 'Acha que devia ter feito a consulta de graça.' },
            { letter: 'D', text: 'Reflete e estuda mais, contudo sem coragem de colocar em prática.' },
            { letter: 'E', text: 'Faz sua faxina energética e pula pro próximo paciente.' }
        ],
        color: '#652878'
    },
    {
        question: '3- Seu maior desafio é:',
        options: [
            { letter: 'A', text: 'Proteger-se de absorver a bad do paciente.' },
            { letter: 'B', text: 'Criar confiança no que faz.' },
            { letter: 'C', text: 'Colocar um valor justo nos seus conhecimentos.' },
            { letter: 'D', text: 'Sair da teoria para a prática.' },
            { letter: 'E', text: 'Chegar em mais gente que precisa.' }
        ],
        color: '#BF75A2'
    },
    {
        question: '4- Como se vê no momento:',
        options: [
            { letter: 'A', text: 'Sobrecarregado com a responsabilidade.' },
            { letter: 'B', text: 'Sempre estudando, nunca vivendo na pele.' },
            { letter: 'C', text: 'Mais amigo que profissional.' },
            { letter: 'D', text: 'Motivado e querendo evoluir sempre.' },
            { letter: 'E', text: 'Paralisado nas dúvidas.' }
        ],
        color: '#BA3430'
    },
    {
        question: '5- Pra você evoluir é:',
        options: [
            { letter: 'A', text: 'Encontrar mecanismos de proteção.' },
            { letter: 'B', text: 'Fazer cursos até sentir que a insegurança passou.' },
            { letter: 'C', text: 'Esperar o tempo resolver.' },
            { letter: 'D', text: 'Estudar mas sem colocar em prática por enquanto.' },
            { letter: 'E', text: 'Investir em mentorias, supervisão e prática reflexiva.' }
        ],
        color: '#E0701B'
    },
    {
        question: '6- Quando ouvir crítica ou feedback:',
        options: [
            { letter: 'A', text: 'Entra em parafuso.' },
            { letter: 'B', text: 'Questiona se escolheu a carreira certa.' },
            { letter: 'C', text: 'Se desculpa e oferece desconto.' },
            { letter: 'D', text: 'Usa como combustível para estudar mais.' },
            { letter: 'E', text: 'Enxerga como chance de se melhorar.' }
        ],
        color: '#E0A824'
    },
    {
        question: '7- O que te motiva é:',
        options: [
            { letter: 'A', text: 'Vontade de se proteger melhor.' },
            { letter: 'B', text: 'Momentos raros de confiança, onde você encontra sentido.' },
            { letter: 'C', text: 'Gratidão dos pacientes.' },
            { letter: 'D', text: 'Saber que sempre tem o que aprender.' },
            { letter: 'E', text: 'Poder ajudar em transformações reais.' }
        ],
        color: '#728037'
    },
];

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function Questionary() {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState({});
    const [loading, setLoading] = useState(true);
    const [shuffledOptions, setShuffledOptions] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        telephone: '',
    });
    const [formValid, setFormValid] = useState(false);
    const [questionsCompleted, setQuestionsCompleted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setShuffledOptions(shuffleArray(questions[currentQuestion].options));
        document.documentElement.style.setProperty('--main-color', questions[currentQuestion].color);
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            const handleLoad = () => {
                setLoading(false);
            };

            window.addEventListener('load', handleLoad);

            return () => window.removeEventListener('load', handleLoad);
        }
    }, [currentQuestion]);

    const handleAnswerChange = (e) => {
        const { name, value } = e.target;
        const originalLetter = questions[currentQuestion].options.find(option => option.text === value).letter;
        const existingAnswers = answers[name] || [];
        const updatedAnswers = [...existingAnswers, originalLetter];
        setAnswers({ ...answers, [name]: updatedAnswers });
    };

    const handleRegistrationChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            const lettersCount = {};
            console.log(lettersCount);
            console.log(answers)
            Object.entries(answers).forEach(([letter, options]) => {
                options.forEach((option) => {
                    lettersCount[option] = (lettersCount[option] || 0) + 1;
                });
            });

            const sortedOptions = Object.keys(lettersCount).sort((a, b) => lettersCount[b] - lettersCount[a]);
            const firstMostSelectedOption = sortedOptions[0];
            const secondMostSelectedOption = sortedOptions[1];

            const answersMap = {
                A: 'O Terapeuta Sensível',
                B: 'O Terapeuta Inseguro',
                C: 'O Amigo-Terapeuta',
                D: 'O Terapeuta Estudante',
                E: 'O Terapeuta Abundante',
            };

            const firstAnswer = answersMap[firstMostSelectedOption];
            const secondAnswer = answersMap[secondMostSelectedOption];

            console.log('First Most Selected Option:', firstMostSelectedOption);
            console.log('Second Most Selected Option:', secondMostSelectedOption);
            console.log('First Answer:', firstAnswer);
            console.log('Second Answer:', secondAnswer);

            navigate('/final');

            if (formData.name && formData.email && formData.telephone) {
                // Send data to backend or perform further actions
                console.log('Form Data:', formData);
            } else {
                console.error('Campos não preenchidos');
            }

        } catch (error) {
            console.error('Erro processando informações:', error);
        }
    };

    const renderOptions = () => {
        return shuffledOptions.map((option, index) => (
            <MDBCol md={12} key={index} className='qa-option-wrapper'>
                <div className='custom-radio'>
                    <input
                        type="radio"
                        id={`option-${index}`}
                        name={`question-${currentQuestion}`}
                        value={option.text}
                        checked={answers[`question-${currentQuestion}`] && answers[`question-${currentQuestion}`].includes(option.letter)}
                        onChange={handleAnswerChange}
                    />
                    <label htmlFor={`option-${index}`} className='qa-option-label' style={{ color: currentQuestion === 0 ? '#162070' : '#000' }}>
                        <div className='circle' data-letter={String.fromCharCode(65 + index)}></div> {/* Use ASCII code for letters */}
                        {option.text}
                    </label>
                </div>
            </MDBCol>
        ));
    };

    const handleNext = () => {
        if (answers[`question-${currentQuestion}`] && answers[`question-${currentQuestion}`].length > 0) {
            setCurrentQuestion(currentQuestion + 1);
            setShowMessage(false);
            if (currentQuestion + 1 === questions.length) {
                setQuestionsCompleted(true); // Set questionsCompleted to true when all questions are answered
            }
        } else {
            setShowMessage(true);
        }
    };

    const Message = ({ show, onClose }) => {
        if (!show) return null;

        return (
            <div className="message">
                <p>Por favor, selecione alguma das opções antes de seguir.</p>
            </div>
        );
    };

    const handlePrevious = () => {
        setCurrentQuestion(currentQuestion - 1);
    };

    const renderTimelineBalls = () => {
        const balls = [];
        for (let i = 0; i <= 6; i++) {
            balls.push(
                <div
                    key={i}
                    className={`mb-4 timeline-ball ${i < currentQuestion ? 'previous' : ''} ${i <= currentQuestion ? 'filled' : ''}`}
                    onClick={() => setCurrentQuestion(i)}
                ></div>
            );
        }
        return balls;
    };

    if (loading) {
        return <LoadingScreen />;
    }

    if (questionsCompleted) {
        return (
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center justify-content-between" style={{ minHeight: '90vh' }}>
                <MDBRow className='mb-4 qa-page-title-wrapper'>
                    <div className='qa-header-bg'>
                        <HeaderBGRegister />
                    </div>
                    <div className='qa-register-logo'>
                        <LogoH />
                    </div>
                </MDBRow>
                <MDBContainer className='mb-4 qa-container'>
                    <MDBRow className='mb-4 ms-4 me-4'>
                        <MDBCol>
                            <h1 className='qa-page-title'>Para receber sua resposta, só temos mais uma etapa:</h1>
                            <p className='qa-page-subtitle'>Realize o cadastro abaixo para descobrir seu perfil de terapeuta holístico</p>
                        </MDBCol>
                        <MDBCol md={12} className='mb-4 d-flex justify-content-center flex-column qa-register-wrapper'>
                            <label className='pe-4'>Nome: </label>
                            <input
                                className='qa-input-register'
                                type='text'
                                name='name'
                                label='Nome'
                                value={formData.name}
                                onChange={handleRegistrationChange}
                            />
                        </MDBCol>
                        <MDBCol md={12} className='mb-4 d-flex justify-content-center flex-column qa-register-wrapper'>
                            <label className='pe-4'>E-mail: </label>
                            <input
                                className='qa-input-register'
                                type='email'
                                name='email'
                                label='E-mail'
                                value={formData.email}
                                onChange={handleRegistrationChange}
                            />
                        </MDBCol>
                        <MDBCol md={12} className='mb-4 d-flex justify-content-center flex-column qa-register-wrapper'>
                            <label className='pe-4'>Telefone: </label>
                            <input
                                className='qa-input-register'
                                type='tel'
                                name='telephone'
                                label='Telefone'
                                value={formData.telephone}
                                onChange={handleRegistrationChange}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <a className='qa-button-r' onClick={handleSubmit}>Enviar</a>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>
        );
    }

    return (
        <>
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center justify-content-between" style={{ minHeight: '90vh' }}>
                <MDBRow className='mb-4 qa-page-title-wrapper'>
                    {/* <img src={QAHeader}></img> */}
                    <div className='qa-header-bg'>
                        <HeaderBG />
                    </div>
                    <div className='qa-header-logo'>
                        <SheilaLogo />
                    </div>
                </MDBRow>
                <MDBContainer className='mb-4 qa-container'>
                    <MDBRow style={{ width: '100%' }} className='qa-questions-wrapper'>
                        <MDBCol>
                            <MDBTypography tag='h1' className='mt-2 mb-4 qa-page-title'>{questions[currentQuestion].question}</MDBTypography>
                        </MDBCol>
                        {renderOptions()}
                    </MDBRow>
                </MDBContainer>
                <Message show={showMessage} onClose={() => setShowMessage(false)} />
                <MDBRow className='mb-4 qa-buttons-wrapper'>
                    <MDBCol sd={4} className=''>
                        {currentQuestion > 0 && <a className='qa-button-l' onClick={handlePrevious}>Voltar</a>}
                    </MDBCol>
                    <MDBCol sd={8} className=''>
                        {currentQuestion < questions.length - 1 && <a className='qa-button-r' onClick={handleNext}>Continuar</a>}
                        {currentQuestion === questions.length - 1 && <a className='qa-button-r' onClick={() => setQuestionsCompleted(true)}>Continuar</a>}</MDBCol>
                </MDBRow>
                <div className="timeline">
                    {renderTimelineBalls()}
                </div>
            </MDBContainer>
        </>
    );
}

export default Questionary;
